var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mb-10",attrs:{"fluid":""}},[_c('v-row',{staticClass:"ml-7",staticStyle:{"width":"96%"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-tabs',{attrs:{"color":"deep-purple accent-4"}},[_c('v-tab',[_vm._v("Add Group Product")]),_c('v-tab',[_vm._v("All Group Products")]),_c('v-tab',[_vm._v("Create Chalan")]),_c('v-tab',[_vm._v("All Chalan")]),_c('v-tab-item',[_c('GroupProduct')],1),_c('v-tab-item',[_c('GroupProductList')],1),_c('v-tab-item',{staticClass:"px-10"},[_c('CreateChalan')],1),_c('v-tab-item',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mt-10 mb-10",attrs:{"elevation":"0"}},[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"background":"#f5f5f5"},attrs:{"headers":_vm.AllChalanHeader,"items":_vm.chalanproducts,"search":_vm.search,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"loading":_vm.loading,"loading-text":"Please wait..."},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"mt-2",staticStyle:{"background":"#f5f5f5"},attrs:{"elevation":"2"}},[_c('v-toolbar-title',{staticClass:"mt-2 mb-2 ml-2",staticStyle:{"margin-left":"-14px"}},[_vm._v(" All Chalan ")]),_c('v-spacer'),_c('div',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","filled":"","rounded":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1)]},proxy:true},{key:"item.viewchalan",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"shaped":"","text":"","color":"primary"},on:{"click":function($event){return _vm.viewchalanAction(item)}}},[_vm._v(" View ")])]}}])})],1)],1)],1)],1)],1)],1)],1)],1)],1),_c('v-dialog',{staticClass:"ma-0",attrs:{"max-width":"1050px"},model:{value:(_vm.showCrop),callback:function ($$v) {_vm.showCrop=$$v},expression:"showCrop"}},[_c('v-card',[_c('v-row',{staticClass:"ma-0"},[_c('v-col',[_c('div',{staticClass:"cut"},[_c('vue-cropper',{ref:"cropper",attrs:{"img":_vm.cropBlob.image,"output-size":_vm.option.size,"output-type":_vm.option.outputType,"info":true,"full":_vm.option.full,"fixed":_vm.fixed,"fixed-number":_vm.fixedNumber,"can-move":_vm.option.canMove,"can-move-box":_vm.option.canMoveBox,"fixed-box":_vm.option.fixedBox,"original":_vm.option.original,"auto-crop":_vm.option.autoCrop,"auto-crop-width":_vm.option.autoCropWidth,"auto-crop-height":_vm.option.autoCropHeight,"center-box":_vm.option.centerBox,"high":_vm.option.high,"mode":"cover","max-img-size":_vm.option.max},on:{"real-time":_vm.realTime}})],1)]),_c('v-col',{staticClass:"text-center",attrs:{"align-self":"center"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('div',{staticClass:"show-preview",style:({
                width: _vm.previews.w + 'px',
                height: _vm.previews.h + 'px',
                overflow: 'hidden',
                border: '1px solid green',
                'margin-bottom': '10px',
              })},[_c('div',{style:(_vm.previews.div)},[_c('img',{style:(_vm.previews.img),attrs:{"src":_vm.previews.url}})])])])],1)],1),_c('v-row',{staticClass:"mx-10 pb-10",attrs:{"justify":"center"}},[_c('v-btn',{attrs:{"color":"red","tile":"","elevation":"0"},on:{"click":function($event){_vm.showCrop = false}}},[_vm._v("Go back")]),_c('v-btn',{attrs:{"color":"success","tile":"","elevation":"0","outlined":""},on:{"click":_vm.getResult}},[_vm._v("Crop & Confirm")])],1)],1)],1),_c('v-snackbar',{attrs:{"centered":"","color":_vm.color},scopedSlots:_vm._u([{key:"action",fn:function(ref){
              var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" ")]),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.viewchalandialog),callback:function ($$v) {_vm.viewchalandialog=$$v},expression:"viewchalandialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2 pb-5"},[_vm._v(" Chalan ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.tempobj.message)+" ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.viewchalandialog = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.viewChalan}},[_vm._v(" View Chalan ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }