<template>
  <v-container fluid class="mb-10">
    <v-row class="ml-7" style="width: 96%">
      <v-col cols="12">
        <v-card>
          <v-tabs color="deep-purple accent-4">
            <v-tab>Add Group Product</v-tab>

            <v-tab>All Group Products</v-tab>

            <v-tab>Create Chalan</v-tab>
            <v-tab>All Chalan</v-tab>
            <!-- This tab is for upload a group  product -->
            <v-tab-item>
              <GroupProduct />
            </v-tab-item>

            <!-- This tab is for upload a group  product -->
            <v-tab-item>
              <GroupProductList />
            </v-tab-item>

            <!-- This Tab  is for create chalan -->
            <v-tab-item class="px-10">
              <CreateChalan />
            </v-tab-item>
            <!-- this is for show chalan list -->
            <v-tab-item>
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-card class="mt-10 mb-10" elevation="0">
                      <v-data-table
                        :headers="AllChalanHeader"
                        :items="chalanproducts"
                        :search="search"
                        class="elevation-1"
                        style="background: #f5f5f5"
                        :single-expand="singleExpand"
                        :expanded.sync="expanded"
                        :loading="loading"
                        loading-text="Please wait..."
                      >
                        <template v-slot:top>
                          <v-toolbar
                            elevation="2"
                            style="background: #f5f5f5"
                            class="mt-2"
                          >
                            <v-toolbar-title
                              style="margin-left: -14px"
                              class="mt-2 mb-2 ml-2"
                            >
                              All Chalan
                            </v-toolbar-title>

                            <v-spacer></v-spacer>
                            <div>
                              <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                filled
                                rounded
                                dense
                                hide-details
                              ></v-text-field>
                            </div>

                            <v-divider class="mx-4" inset vertical></v-divider>
                          </v-toolbar>
                        </template>

                        <template v-slot:item.viewchalan="{ item }">
                          <v-btn
                            shaped
                            text
                            @click="viewchalanAction(item)"
                            color="primary"
                          >
                            View
                          </v-btn>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="showCrop" max-width="1050px" class="ma-0">
      <v-card>
        <v-row class="ma-0">
          <v-col>
            <div class="cut">
              <vue-cropper
                ref="cropper"
                :img="cropBlob.image"
                :output-size="option.size"
                :output-type="option.outputType"
                :info="true"
                :full="option.full"
                :fixed="fixed"
                :fixed-number="fixedNumber"
                :can-move="option.canMove"
                :can-move-box="option.canMoveBox"
                :fixed-box="option.fixedBox"
                :original="option.original"
                :auto-crop="option.autoCrop"
                :auto-crop-width="option.autoCropWidth"
                :auto-crop-height="option.autoCropHeight"
                :center-box="option.centerBox"
                @real-time="realTime"
                :high="option.high"
                mode="cover"
                :max-img-size="option.max"
              ></vue-cropper>
            </div>
          </v-col>
          <v-col class="text-center" align-self="center">
            <v-row justify="center">
              <div
                class="show-preview"
                :style="{
                  width: previews.w + 'px',
                  height: previews.h + 'px',
                  overflow: 'hidden',
                  border: '1px solid green',
                  'margin-bottom': '10px',
                }"
              >
                <div :style="previews.div">
                  <img :src="previews.url" :style="previews.img" />
                </div>
              </div>
            </v-row>
            <!-- <div class="d-flex flex-row justify-space-between mr-6">
              <v-btn @click="showCrop = false" color="red" tile elevation="0"
                >Go back</v-btn
              >

              <v-btn
                @click="getResult"
                color="success"
                tile
                elevation="0"
                outlined
                >Crop & Confirm</v-btn
              >
            </div> -->
          </v-col>
        </v-row>

        <v-row justify="center" class="mx-10 pb-10">
          <v-btn @click="showCrop = false" color="red" tile elevation="0"
            >Go back</v-btn
          >

          <v-btn @click="getResult" color="success" tile elevation="0" outlined
            >Crop & Confirm</v-btn
          >
        </v-row>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" centered :color="color">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="viewchalandialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2 pb-5">
          Chalan
        </v-card-title>

        <v-card-text>
          {{ tempobj.message }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="viewchalandialog = false">
            Close
          </v-btn>
          <v-btn color="primary" text @click="viewChalan"> View Chalan </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>


<script>
import { VueCropper } from "vue-cropper";

export default {
  data: () => ({
    crap: false,
    previews: {},
    SpecificationArray: [],
    searchspec: "",
    option: {
      img: "",
      size: 1,
      full: false,
      outputType: "image/png",
      canMove: true,
      fixedBox: true,
      original: false,
      canMoveBox: true,
      autoCrop: true,
      autoCropWidth: 475,
      autoCropHeight: 475,
      centerBox: false,
      high: true,
      max: 99999,
    },
    show: true,
    fixed: true,
    fixedNumber: [1, 1],

    menu: false,
    menu2: false,
    search: "",
    adddialog: false,
    editdialog: false,
    valid: false,
    snackbar: false,
    text: "",
    color: "",
    check: true,
    validForm: false,
    editProductForm: false,
    loading: true,

    dialog1: false,
    new_category: "",

    dialog2: false,
    new_sub_category: "",

    dialog3: false,
    new_sub_sub_category: "",

    category: [],
    selectedCatyegory: "",

    sub_category: [],
    selectedSubCatyegory: "",

    sub_sub_category: [],
    selectedSubSubCategory: "",

    shares: ["Share with mother site", "Don't share with mother site"],
    select_val: ["kg", "pcs", "gm", "none"],
    headers: [
      { text: "ID", value: "id", sortable: false },
      { text: "Title", value: "title" },
      { text: "Brand", value: "brand", sortable: false },
      { text: "No.Spec", value: "specific_status", sortable: false },
      { text: "", value: "specification", sortable: false },
      { text: "", value: "status", sortable: false },
      // { text: "Selling Price", value: "old_price" },
      { text: "", value: "review", sortable: false },
      { text: "", value: "comments", sortable: false },
      { text: "", value: "link", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
      { text: "", value: "data-table-expand" },
    ],
    specificationheaders: [
      { text: "product ID", value: "product_id", sortable: false },
      { text: "Specification ID", value: "id", sortable: false },
      { text: "Title", value: "product_data.title" },
      { text: "Brand", value: "product_data.brand", sortable: false },
      { text: "Color", value: "color", sortable: false },
      { text: "Size", value: "size", sortable: false },
      { text: "quantity", value: "quantity", sortable: false },
      { text: "", value: "adquantity", sortable: false },

      // { text: "Selling Price", value: "old_price" },
      // { text: "Purchase Price", value: "review", sortable: false },
      // { text: "Selling Price", value: "comments", sortable: false },
      { text: "Discount", value: "adddiscount", sortable: false },
      { text: "", value: "status", sortable: false },
    ],

    TransitModelHeader: [
      { text: "ID", value: "id", sortable: false },
      { text: "product ID", value: "product_id", sortable: false },
      { text: "Specification ID", value: "specification_id", sortable: false },
      { text: "Title", value: "product_name" },
      { text: "Brand", value: "brand_name", sortable: false },
      { text: "Color", value: "product_color", sortable: false },
      { text: "Size", value: "product_size", sortable: false },
      { text: "quantity", value: "requested_qty", sortable: false },
      { text: "Purchase ", value: "purchase_price", sortable: false },
      { text: "Sell", value: "selling_price", sortable: false },
    ],
    ChalanHeader: [
      { text: "product ID", value: "product_id", sortable: false },
      { text: "Specification ID", value: "id", sortable: false },
      { text: "Title", value: "product_name" },
      { text: "Brand", value: "brand_name", sortable: false },
      { text: "Color", value: "product_color", sortable: false },
      { text: "Size", value: "product_size", sortable: false },
      { text: "quantity", value: "credit", sortable: false },
      { text: "Purchase ", value: "purchase_price", sortable: false },
      { text: "Selling", value: "selling_price", sortable: false },
    ],
    AllChalanHeader: [
      { text: "Chalan ID", value: "id", sortable: false },
      { text: "Created By", value: "created_by", sortable: false },
      { text: "Approved By", value: "approved_by", sortable: false },
      { text: "Date", value: "date", sortable: false },
      { text: "Status", value: "chalan_status", sortable: false },
      { text: "Name", value: "name" },
      { text: "Location", value: "location", sortable: false },
      { text: "", value: "viewchalan", sortable: false },
    ],
    products: [],
    contact: {},
    editedIndex: -1,
    allBrands: [],

    editedItem: {},

    addItem: {
      radioGroup: false,
      selectedCatyegory: "",
      selectedSubCatyegory: "",
      selectedSubSubCategory: "",
      title: "",
      brand: "",
      description: "",
      key_features: [],
      warranty: "",
      // properties: "",
      origin: "Bangladesh",
      shipping: "Bangladesh",
      description: "",
      image: [],
      publish_st: "Published",
    },

    images: [],
    previewURLS: [],

    user: {},

    title: "",
    brand: "",
    publish_st: "Published",
    description: "",
    key_features: [],
    quantity: "",
    // properties: true,
    point: "",
    price: "",
    purchase_price: "",
    discount_type: "",
    amount: "",
    discount_end_date: "",
    point_end_date: "",
    order: "",
    shipping: "Bangladesh",
    origin: "Bangladesh",
    manufactureCountries: [
      "Bangladesh",
      "India",
      "Pakistan",
      "China",
      "Sri Lanka",
      "Malaysia",
      "Indonesia",
      "Nepal",
      "Bhutan",
      "Maldives",
      "Japan",
      "Philippines",
      "Vietnam",
      "Turkey",
      "Iran",
      "Thailand",
      "Myanmar",
      "South Korea",
      "Saudi Arabia",
      "United Arab Emirates",
      "Singapore",
      "Kuwait",
      "Qatar",
      "Brunei",
      "Bahrain",
    ],
    warranty: "",
    product_unit: "",

    includeFiles: true,
    enabled: false,

    expanded: [],
    singleExpand: false,

    cropBlob: {},
    showCrop: false,
    resultURL: "",
    discount_types: ["none", "amount", "percentage"],
    select: { state: "All Products" },
    items: [
      { state: "All Products" },
      { state: "No Specification" },
      { state: "No Price" },
    ],

    selectspec: { statespec: "All Specifications" },
    itemspec: [
      { statespec: "All Specifications" },
      { statespec: "Shared Specification" },
    ],
    addquantitydialog: false,
    shop: [],
    warehouse: [],
    priceValidationForm: false,
    specdataobj: {
      product_data: {},
      product_price: {
        price: 0,
        purchase_price: 0,
      },
    },
    searchchalan: "",
    AllWarehouseList: [],
    trnasferob: {},
    selected: [],
    Transfersavedialog: false,
    singleSelect: false,
    valid_add_discount: false,
    addDiscountDialog: false,
    specdataobjdiscount: {
      product_data: {},
    },
    product_dist: {
      discount_type: "none",
      discount_amount: 0,
      discount_start_date: "",
      discount_end_date: "",
    },

    menudist: false,
    menu2dist: false,
    addedwarehousequantity: [],
    addedshopquantity: [],
    quantityIndex: -1,
    quantityID: -1,
    allchalanArray: [],
    chalanproducts: [],
    allChalanobj: {},
    viewchalandialog: false,
    tempobj: {
      data: {},
    },
    searchitemArray: [],
  }),

  components: {
    VueCropper,
    GroupProduct: () => import(/* */ "@/components/groupProduct.vue"),
    GroupProductList: () => import(/* */ "@/components/GroupProductList.vue"),
    CreateChalan: () => import(/* */ "@/components/createGroupChalan.vue"),
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  watch: {
    addquantitydialog: function (newValue) {
      this.addedwarehousequantity.length = 0;
      this.addedshopquantity.length = 0;

      // IF add quantity and price modal is closed then reset all the variable
      if (!newValue) {
        this.quantityIndex = -1;
        this.quantityID = -1;
        this.$refs.priceForm.reset();
      }
    },
  },

  methods: {
    initialize() {
      axios.get("manager/transferable_list").then((response) => {
        if (response.data.success) {
          this.AllWarehouseList = response.data.data;
          this.AllWarehouseList.forEach((element, index) => {
            element.flagvalue = element.value;
            element.value = index;
          });
        }
      });

      axios.get("manager/show_all_chalan/").then((response) => {
        if (response.data.success) {
          this.chalanproducts = response.data.data;
        }
      });
    },

    getAllBrands() {
      axios.get("productdetails/get_brands/").then((response) => {
        this.allBrands = response.data.data;
      });
    },

    addFeatures() {
      this.addItem.key_features.push("");
    },

    deleteFeatures(index) {
      this.addItem.key_features.splice(index, 1);
    },

    addFeaturesForEditProduct() {
      this.editedItem.key_features.push("");
    },

    deleteFeaturesForEditProduct(index) {
      this.editedItem.key_features.splice(index, 1);
    },

    DiscountEndInput() {
      this.discount_end_date = this.date;

      this.menu = false;
    },

    PointEndInput() {
      this.point_end_date = this.dates;

      this.menu2 = false;
    },

    Preview_image() {
      this.images.forEach((image) => {
        if (/\.(jpg|png|jpeg)$/i.test(image.name) == false) {
          alert("We accept image file ends with jpg/jpeg/png only !");
          this.images.length = 0;
          this.addItem.image.length = 0;
          return;
        }

        let previewObject = {};

        const reader = new FileReader();
        reader.onload = (e) => {
          previewObject.image = e.target.result;
        };
        reader.readAsDataURL(image);

        let BlobObject = URL.createObjectURL(image);

        let img = new Image();
        img.src = BlobObject;

        img.onload = () => {
          let w = img.width;
          let h = img.height;

          let excedeFlag = false;

          if (w > 475 || h > 475) {
            excedeFlag = true;
          }

          previewObject.excede = excedeFlag;
          previewObject.name = image.name;
          previewObject.type = image.type;
          this.addItem.image.push(previewObject);
        };
      });
    },

    cropModal(image, index) {
      this.cropBlob = image;
      this.cropBlob.index = index;
      this.showCrop = true;
    },

    realTime(data) {
      this.previews = data;
    },

    getResult() {
      this.$refs.cropper.getCropData((data) => {
        this.addItem.image[this.cropBlob.index].image = data;
        this.addItem.image[this.cropBlob.index].excede = false;
        this.showCrop = false;
      });
    },

    deleteImage(index) {
      this.addItem.image.splice(index, 1);
      this.images.splice(index, 1);
    },

    deleteAllImage() {
      this.images.length = 0;
      this.addItem.image.length = 0;
    },

    save(status) {
      //Upload a product

      var formData = new FormData();

      // formData.append("properties", this.addItem.radioGroup);
      formData.append("category", this.addItem.selectedCatyegory);
      formData.append("sub_category", this.addItem.selectedSubCatyegory);
      formData.append("sub_sub_category", this.addItem.selectedSubSubCategory);
      formData.append("title", this.addItem.title);
      formData.append("brand", this.addItem.brand);
      formData.append("description", this.addItem.description);
      formData.append("key_features", this.addItem.key_features);
      formData.append("shipping_country", this.addItem.shipping);
      formData.append("origin", this.addItem.origin);
      formData.append("count", this.addItem.image.length);
      formData.append("publish", status);
      formData.append("user_role", this.user.role);
      formData.append("user_id", this.user.user_id);

      // Image append in form data array
      for (var i = 0; i < this.addItem.image.length; i++) {
        formData.append("images[" + i + "]", this.addItem.image[i].image);
      }

      for (var pair of formData.entries()) {
        // console.log(pair[0] + ", " + pair[1]);
      }

      axios
        .post(`product/add_product/`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response.data.success) {
            this.products.unshift(response.data.product_data);
            this.text = "Product has been uploaded successfully";
            // this.text = response.data.message;
            this.color = "success";
            this.$refs.productForm.reset();
            this.addItem.key_features.length = 0;
            this.addItem.image.length = 0;
            this.addItem.radioGroup = false;
          } else {
            //this.text = "Something went wrong !!";
            this.text = response.data.message;
            this.color = "error";
          }
          this.snackbar = true;
        })
        .catch((err) => {
          //this.text = "Something went wrong !!";
          this.text = response.data.message;
          this.color = "error";
          this.snackbar = true;
        });
    },

    saveEditProduct(image) {
      var formData = new FormData();
      formData.append("category", this.editedItem.category);
      formData.append("sub_category", this.editedItem.sub_category);
      formData.append("sub_sub_category", this.editedItem.sub_sub_category);
      formData.append("title", this.editedItem.title);
      formData.append("brand", this.editedItem.brand);
      formData.append("description", this.editedItem.description);
      formData.append("key_features", this.editedItem.key_features);
      formData.append("shipping_country", this.editedItem.shipping_country);
      formData.append("origin", this.editedItem.origin);
      formData.append("user_role", this.user.role);
      formData.append("user_id", this.user.user_id);

      // Object.assign(this.products[this.editedIndex], this.editedItem);
      //  for (var pair of formData.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }

      axios
        .post(`product/modify_product/${this.editedItem.id}/`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response.data.success) {
            let ID = this.editedItem.id;
            Object.assign(this.products[this.editedIndex], this.editedItem);
            this.text = "Product has been edited successfully";
            this.color = "success";
            this.editedItem = {};
            this.snackbar = true;
            this.editdialog = false;

            if (image == "image") {
              this.$router.push({
                name: "Edit Product Image",
                params: { id: ID },
                query: { spm: ID },
              });
            }
          } else {
            //this.text = "Something went wrong !!";
            this.text = response.data.message;
            this.color = "error";
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.text = "Something went wrong !!";
          this.color = "error";
          this.snackbar = true;
        });
    },

    saveCategory() {
      this.category.push(this.new_category);
      this.dialog1 = false;
    },

    AddSubCategory() {
      this.sub_category.push(this.new_sub_category);
      this.dialog2 = false;
    },
    unpublish() {},

    addSubSubCategory() {
      this.sub_sub_category.push(this.new_sub_sub_category);
      this.dialog3 = false;
    },

    editItem(item) {
      this.editedIndex = this.products.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editdialog = true;
    },

    deleteItem(item) {
      if (confirm("Do you really want to delete?")) {
        axios.post(`product/hide_delete/${item.id}/`).then((response) => {
          this.products = this.products.filter(
            (contact) => contact.id != item.id
          );
        });
      }
    },

    viewSpecification(item) {
      let routeData = this.$router.resolve({
        name: `/supportdashboard/pages/specification/${item.id}`,
      });
      routeData.href = `/supportdashboard/pages/specification/?id=${item.id}`;
      window.open(routeData.href, "_blank");
    },

    viewReview(item) {
      let routeData = this.$router.resolve({
        name: `/supportdashboard/pages/review/${item.id}`,
      });
      routeData.href = `/supportdashboard/pages/review/?id=${item.id}`;
      window.open(routeData.href, "_blank");
    },

    viewComments(item) {
      let routeData = this.$router.resolve({
        name: `/supportdashboard/pages/viewcomments/${item.id}`,
      });
      routeData.href = `/supportdashboard/pages/viewcomments/?id=${item.id}`;
      window.open(routeData.href, "_blank");
    },

    viewLink(item) {
      let routeData = this.$router.resolve({
        name: `https://mymarket.com.bd/products/${item.title.replaceAll(
          " ",
          "-"
        )}/details/?spm=${item.id}`,
      });
      routeData.href = `https://mymarket.com.bd/products/${item.title.replaceAll(
        " ",
        "-"
      )}/details/?spm=${item.id}`;
      window.open(routeData.href, "_blank");
    },

    getAllCastegory() {
      axios.get("category/categories/").then((response) => {
        this.category = response.data;
      });
    },

    onSelectCategory() {
      axios
        .post(`category/subcategories/`, {
          name: this.addItem.selectedCatyegory,
        })
        .then((response) => {
          this.sub_category = response.data;
        });
    },

    // Product publish/unpublish
    action(item) {
      const index = this.products.indexOf(item);
      axios
        .get(`product/publish_unpublish_product/${item.id}/`)
        .then((response) => {
          this.products[index].product_status = response.data.product_status;
          window.location.reload(true);
        });
    },

    // Specification publish/unpublish
    onSpecificationPublishUnpublish(item) {
      const index = this.SpecificationArray.indexOf(item);
      axios
        .get(`product/publish_unpublish_specification/${item.id}/`)
        .then((response) => {
          this.SpecificationArray[index].specification_status =
            response.data.specification_status;
        });
    },

    // This Link will be Change for link change
    print(select) {
      if (this.select == "All Products") {
        let routeData = this.$router.resolve({
          // name: `http://68.183.231.43/site/get_pdf/`,
          name: `site/get_pdf/`,
        });
        //routeData.href = `http://68.183.231.43/site/get_pdf/`;
        routeData.href = axios.defaults.baseURL + "site/get_pdf/";
        //  console.log(axios.defaults.baseURL+"site/get_pdf/")
        window.open(routeData.href, "_blank");
      } else if (this.select == "No Specification") {
        let routeData = this.$router.resolve({
          name: `get_nospec_pdf/`,
          //name: `http://68.183.231.43/site/get_nospec_pdf/`,
        });
        //routeData.href = `http://68.183.231.43/site/get_nospec_pdf/`;
        routeData.href = axios.defaults.baseURL + "site/get_nospec_pdf/";
        window.open(routeData.href, "_blank");
      } else if (this.select == "No Price") {
        let routeData = this.$router.resolve({
          // name: `http://68.183.231.43/site/get_nospecPrice_pdf/`,
          name: `site/get_nospecPrice_pdf/`,
        });
        //routeData.href = `http://68.183.231.43/site/get_nospecPrice_pdf/`;
        routeData.href = axios.defaults.baseURL + "site/get_nospecPrice_pdf/";
        window.open(routeData.href, "_blank");
      } else {
        let routeData = this.$router.resolve({
          // name: `http://68.183.231.43/site/get_pdf/`,
          name: `site/get_pdf/`,
        });
        //routeData.href = `http://68.183.231.43/site/get_pdf/`;
        routeData.href = axios.defaults.baseURL + "site/get_pdf/";
        window.open(routeData.href, "_blank");
      }

      // console.log("this is for method test" ,this.select)
      // let routeData = this.$router.resolve({
      //   name: `https://tes.com.bd/site/get_pdf/`,
      // });
      // routeData.href = `https://tes.com.bd/site/get_pdf/`;
      // window.open(routeData.href, "_blank");
    },
    onSelectSubCategory() {
      axios
        .post(`category/subsubcategories/`, {
          name: this.addItem.selectedSubCatyegory,
        })
        .then((response) => {
          this.sub_sub_category = response.data;
        });
    },

    productselect() {
      if (this.select == "All Products") {
        axios.get("product/product_details/").then((response) => {
          this.products = response.data;
          this.loading = false;
        });
      } else if (this.select == "No Specification") {
        axios.get("product/nospecification_products/").then((response) => {
          this.products = response.data.data;
          this.loading = false;
        });
      } else if (this.select == "No Price") {
        axios.get("product/noprice_products/").then((response) => {
          this.products = response.data.data;
          this.loading = false;
        });
      } else {
        axios.get("product/product_details/").then((response) => {
          this.products = response.data;
          this.loading = false;
        });
      }
    },
    Specificationselect() {
      if (this.selectspec == "All Specifications") {
        axios.get("productdetails/getallSpecification/").then((response) => {
          this.SpecificationArray = response.data.data;
          this.loading = false;
        });
      } else if (this.selectspec == "Shared Specification") {
        axios
          .get("productdetails/all_own_shared_products/")
          .then((response) => {
            this.SpecificationArray = response.data.data;

            this.loading = false;
            // console.log("fjgbfg", this.SpecificationArray);
          });
      } else {
        axios.get("productdetails/getallSpecification/").then((response) => {
          this.SpecificationArray = response.data.data;
          this.loading = false;
        });
      }
    },
    ADDQuantity(item) {
      this.quantityID = item.id;
      this.quantityIndex = this.SpecificationArray.findIndex(
        (spec) => spec.id == item.id
      );
      Object.assign(this.specdataobj, item);
      // console.log(this.specdataobj);

      axios
        .get(`productdetails/quantity_info/${item.id}/`)
        .then((response) => {
          if (response.data.success) {
            this.shop = response.data.data.shop;
            this.warehouse = response.data.data.warehouse;
            this.addquantitydialog = true;
          } else {
            this.color = "red";
            this.text = "Something went wrong !";
            //this.text = response.data.message;
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.color = "red";
          this.text = "Something went wrong !";
          //this.text = response.data.message;
          this.snackbar = true;
        });
    },

    // Add a new warhouse if user give quantity
    warehouseAdd(item) {
      //check array length for new item or not
      if (this.addedwarehousequantity.length > 0) {
        let index = this.addedwarehousequantity.findIndex(
          (warhouse) => warhouse.warehouse_id == item.warehouse_id
        );

        if (index != -1) {
          this.addedwarehousequantity[index].quantity = item.quantity;
        } else {
          this.addedwarehousequantity.push(item);
        }
      } else {
        this.addedwarehousequantity.push(item);
      }
    },

    // Add a new Shop if user give quantity
    shopAdd(item) {
      if (this.addedshopquantity.length > 0) {
        let index = this.addedshopquantity.findIndex(
          (shop) => shop.shop_id == item.shop_id
        );
        if (index != -1) {
          this.addedshopquantity[index].quantity = item.quantity;
        } else {
          this.addedshopquantity.push(item);
        }
      } else {
        this.addedshopquantity.push(item);
      }
    },

    confirmquantity(specdataobj) {
      let datas = {
        product_id: this.specdataobj.product_id,
        specification_id: this.quantityID,
        warehouse: this.addedwarehousequantity,
        shop: this.addedshopquantity,
        purchase_price: Number(this.specdataobj.product_price.purchase_price),
        selling_price: Number(this.specdataobj.product_price.price),
      };

      let total = 0;

      datas.warehouse.forEach((element) => {
        total = Number(total) + Number(element.quantity);
      });

      datas.shop.forEach((element) => {
        total = Number(total) + Number(element.quantity);
      });
      // console.log("this is datasss", datas);
      // If user did not add any quantity throw error
      if (!total) {
        this.text = "Quantity can not be empty";
        this.color = "warning";
        this.snackbar = true;
      } else {
        // If quantity and price exists
        axios
          .post(
            `productdetails/insert_specification_quantity/${this.user.user_id}/`,
            datas
          )
          .then((response) => {
            if (response.data.success) {
              this.text = response.data.message;
              this.color = "success";
              this.addquantitydialog = false;
              this.snackbar = true;
            } else {
              this.text = response.data.message;
              this.color = "warning";
              this.addquantitydialog = false;
              this.snackbar = true;
            }
          })
          .catch((err) => {
            this.text = "Something went wrong ";
            this.color = "warning";
            this.addquantitydialog = false;
            this.snackbar = true;
          });
      }
    },
    savetransit(selected) {
      // console.log("this is ", this.selected);
      this.Transfersavedialog = true;
    },
    CancelInvoice() {
      this.Transfersavedialog = false;
    },
    add_discount(item) {
      this.addDiscountDialog = true;
      Object.assign(this.specdataobjdiscount, item);
      // console.log(this.specdataobjdiscount);
    },
    Canceldiscount() {
      this.addDiscountDialog = false;
    },
    saveDist(specdataobjdiscount) {
      let datas = {
        discount_type: this.product_dist.discount_type,
        discount_amount: this.product_dist.discount_amount,
        discount_start_date: this.product_dist.discount_start_date,
        discount_end_date: this.product_dist.discount_end_date,
        product_id: this.specdataobjdiscount.product_id,
      };
      // console.log("this is item", datas);

      axios
        .post(`site/add_discount/${this.specdataobjdiscount.id}/`, datas)
        .then((response) => {
          if (response.data.success) {
            this.text = response.data.message;
            this.color = "success";
            this.snackbar = true;
            this.addDiscountDialog = false;
          } else {
            this.text = response.data.message;
            this.color = "error";
            this.snackbar = true;
          }
        });
    },
    TransferSelectInfo() {
      // console.log(this.trnasferob);
      axios
        .get(
          `productdetails/get_product_list/${this.trnasferob.flagvalue}/${this.trnasferob.id}/`
        )
        .then((response) => {
          if (response.data.success) {
            this.allchalanArray = response.data.data;
          }
        });
    },

    SaveApproveInvoice(trnasferob) {
      let datas = {
        user_id: this.user.user_id,
        transaction_info: this.selected,
      };
      axios
        .post(`manager/create_chalan/${this.trnasferob.flagvalue}/`, datas)
        .then((response) => {
          if (response.data.success) {
            // this.text = response.data.message;
            this.tempobj = response.data;
            // this.color = "success";
            // this.snackbar = true;
            this.Transfersavedialog = false;
            this.allchalanArray = this.allchalanArray.filter((element) => {
              this.selected.some((item) => item.id == element.id);
            });
            this.selected = [];
            this.TransferSelectInfo();
            this.viewchalandialog = true;
          } else {
            this.text = response.data.message;
            this.color = "error";
            this.snackbar = true;
          }
        });
    },
    viewchalanAction(item) {
      let routeData = this.$router.resolve({
        name: `/supportdashboard/pages/admin_chalan/${item.id}`,
      });
      routeData.href = `/supportdashboard/pages/admin_chalan/?id=${item.id}`;
      window.open(routeData.href, "_blank");
    },
    viewChalan(tempobj) {
      // console.log("this is clicked", tempobj);
      this.$router.push({
        path: "/supportdashboard/pages/admin_chalan/",
        query: { id: this.tempobj.data.id },
      });
    },

    myItems(items) {
      // console.log("After sorted", items);
      this.searchitemArray = items;
    },
    printSpec(searchitemArray) {
      // console.log("this is search data array", this.searchitemArray);

      let href = axios.defaults.baseURL + "site/allspec_pdf";
      window.open(href, "_blank");

      // axios
      //   .get("site/search_spec_pdf/" ,this.searchitemArray)
      //   .then((response) => {

      //    let routeData = this.$router.resolve({

      //       name: `site/search_spec_pdf/`,
      //     });

      //     let href = axios.defaults.baseURL + "site/search_spec_pdf/?"+ "myarray="+;

      //     window.open(href, "_blank");
      //   });
    },
  },
  created() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  mounted() {
    this.initialize();
    this.getAllCastegory();
    this.getAllBrands();
    this.productselect();
    this.Specificationselect();
    this.ADDQuantity();
    this.TransferSelectInfo();
  },
};
</script>


<style>
.cut {
  width: 500px;
  height: 560px;
  margin: 30px auto;
  margin-top: 0px;
}

.c-item {
  max-width: 800px;
  margin: 10px auto;
  margin-top: 20px;
}

.content {
  margin: auto;
  max-width: 1200px;
  margin-bottom: 100px;
}

.cropper {
  height: 600px;
  background: #ddd;
}

.cropper-view-box {
  outline: 1px solid red !important;
}

.thumbnailImage:hover {
  cursor: pointer;
}

.v-sheet--offset {
  top: -10px;
  position: relative;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.19rem;
  height: 48px;
}
</style>

